// Entry point for the build script in your package.json
if (!window.Turbo) {
  require("@hotwired/turbo-rails")
}
import "./controllers"
import * as bootstrap from "bootstrap"

// jquery
import "./src/jquery"
// datatables
import DataTable from "datatables.net-bs5"
// window.DataTable = DataTable();
// tom-select
import TomSelect from 'tom-select/dist/esm/tom-select.complete'
window.TomSelect = TomSelect;

// custom
import "./src/searchbox"
import "./src/draftplayersearch"

// import "./src/jquery-ui"

// channels
import "./channels"

$(function() {
  console.log("hello world")
});
