import consumer from "./consumer"

consumer.subscriptions.create("NotifyDraftPickChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var draft_action = data.draft_action;
    if(draft_action) {
        console.log("Reloading due to draft action: ${draft_action}")
        // window.location.replace(window.location.href);
        window.location.reload(true);
    }
  }
});
